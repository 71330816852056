import React, { Component } from 'react';
import { Layout, SEO, Image} from '../../components';
import { validateArray } from '../../components/utilities/functions';
import moment from "moment";
import './index.scss';

class BlogDetailsPage extends Component {

    render() {

        const { pageContext } = this.props;
		const { url, seo, blog_title, author_name, content_block, date, uid='', locale="" } = (pageContext.node) || {};

		const extension = {
			contenttype:(pageContext?.contentType || ''),
			uid:uid,
			locale:locale
		}

        return (
            <Layout pageURL={url}>
                <SEO pageURL={url} {...seo} extension={extension} />
                <div className="container container--980">
                    <div className="blog-lp-wrapper">
                        {
                            blog_title &&
                            <h1 className="blog-title">{ blog_title }</h1>
                        }
                        {
                            author_name &&
                            <span className="author">By { author_name }, </span>
                        }
                        {
                            date &&
                            <span className="date"> { moment(date).format('MMM Do YYYY') }</span>
                        }

                        {/* {
                            blog_image &&
                            <div className="blog-image">
                                <Image data={{ image: .pageContext.node.blog_image}} />
                            </div>
                        } */}
                        {
                            validateArray(content_block) &&
                            <div className='content-block'>
                                {
                                    content_block.map((item, index) =>
                                        <div className='blog-col' key={index}>
                                            {
                                                item.description && item.description.block &&
                                                <div dangerouslySetInnerHTML={{ __html: item.description.block }} />
                                            }
                                            {
                                                item.image && item.image.image &&
                                                <Image className="img-responsive img-block" data={{ image: item.image.image, image_alt:item.image.image_alt}} />
                                            }
                                            {
                                                item.video && item.video.embed_url &&
                                                <div className="resp-iframe">
                                                    <div className="embed-responsive-item">
                                                        <iframe src={item.video.embed_url} title={item.video.title} allowfullscreen></iframe>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        }
                    </div>
                </div>
            </Layout>
        )
    }
}

export default BlogDetailsPage;
